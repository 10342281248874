<template>
    <div>
        <h1>Пользовательское соглашение</h1>
        <input-content
            :items="inputItems"
            :model="model"
        />
        <v-btn
            width="160"
            height="44"
            color="primary"
            @click="onSubmit"
            class="mt-2"
        >
            Сохранить
        </v-btn>
    </div>
</template>

<script>
import InputContent from '@/components/inputs/inputContent.vue'
import axios from 'axios'
export default {
    components: {
        InputContent
    },
    data:() => ({
        model: {},
        inputItems: [
            {
                type: 'editor',
                model: 'text'
            },
        ]
    }),
    async created() {
        await this.getText()
    },
    methods: {
        async onSubmit() {
            try {
                this.$loading(true)
                if (this.model.id) {
                    await axios.patch(`/user-agreement/${this.model.id}/`, this.model)
                } else {
                    await axios.post('/user-agreement/', this.model)
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        async getText() {
            const { data } = await axios.get('/user-agreement/')
            this.model = data.results[0] ?? {}
        }
    }
}
</script>

<style lang="scss" scoped>
h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #52B1BA;
    margin-bottom: 20px;
}
</style>